import React, {useContext, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {ProductContext} from "../../Contexts/ProductContext";
import ProductCard from "../Product/ProductCard";
import {ReviewContext} from "../../Contexts/ReviewContext";  // Assuming you have a ProductCard component

const CategoryPreview = ({handle}) => {
    const {products, filterProductsByCollection} = useContext(ProductContext);
    const {getReviewsByProductHandle} = useContext(ReviewContext);
    const [displayProducts, setDisplayProducts] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        // Determine which products to display based on the 'handle'
        const filteredProducts = handle === "all" ? products : filterProductsByCollection(handle);
        const isMobile = window.innerWidth < 768; // Mobile if width < 768px
        const limit = isMobile ? 2 : 4;
        setDisplayProducts(filteredProducts.slice(0, limit));
    }, [filterProductsByCollection, handle, products]);

    const handleShowAll = () => {
        // Navigate to the collection or show all products page
        navigate(`/collections/${handle}`);
    };

    const calculateAverageRating = (productHandle) => {
        const reviews = getReviewsByProductHandle(productHandle);
        if (reviews.length === 0) return 0; // No reviews, return 0 as average rating

        const totalRating = reviews.reduce((acc, review) => acc + review.rating, 0);
        return totalRating / reviews.length;
    };

    return (<div className="flex flex-col items-center mb-20">
            <div className="md:mt-0 mt-16 w-full">
                <div className="text-left items-start justify-start mb-8 w-full px-4 md:ml-4">
                    <h1 className="text-[#A4A4A4] text-[24px] md:text-[40px] font-semibold">
                        {handle === "all" ? "All Products" : `${handle.charAt(0).toUpperCase() + handle.slice(1)}`}
                    </h1>
                </div>

                <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mb-8 w-full">
                    {displayProducts.map((product, index) => {
                        const averageRating = calculateAverageRating(product.product_handle);  // Assuming you calculate this from reviews
                        const reviewsCount = getReviewsByProductHandle(product.product_handle).length;  // Fetch reviews count here

                        return (
                            <ProductCard
                                key={product.id}
                                product={product}
                                averageRating={averageRating}
                                reviewsCount={reviewsCount}
                                shortReview={true}
                            />
                        );
                    })}
                </div>

                {/* Center the button */}
                <div className="flex justify-center mt-8">
                    <button
                        onClick={handleShowAll}
                        className="px-7 py-3 font-bold bg-white text-black text-[15px] rounded-full hover:bg-gray-200 transition"
                    >
                        Zobrazit vše
                    </button>
                </div>
            </div>
        </div>
    );
};

export default CategoryPreview;
